import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import pic04 from '../assets/images/archetypes/MJ/new/creator.webp'
import Graph from '../components/graph'
import Mail from '../components/mail'
import MyVideo from '../components/video'
import { Link } from "gatsby"
import More from '../components/more'
class Result extends React.Component {



  constructor(props) {

    super(props);
    console.log(props);


    this.state = this.props.location.state;



  }


  componentWillMount() {
    console.log("result page");
    console.log(this.state);
  }



  render() {
    let name = "Creator";
    return (
      <Layout>
        <Helmet title={name} />
        <HeaderGeneric text={name} subtext="Expresses himself"/>
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={pic04} alt="" /></span>


            <h2>{name}</h2>
            
            <p>The Creator archetype fuels the passion and drive behind all artistic and innovative endeavors, but it also holds the potential to consume and control our lives. Without proper channeling, this archetype can lead to a constant need for new projects and a fear that all is an illusion. It can cause us to neglect our relationships and our own self-discovery in pursuit of proving reality outside of our minds.
But let me tell you, ignoring this archetype is not the solution. It will only lead to a life of stagnation and unfulfillment. The key is to find a balance, to harness the energy of the Creator archetype and use it to express ourselves in beautiful and meaningful ways.
For those struggling with this archetype, I highly recommend reading <a href={"https://jilecek.itch.io/the-inner-world-of-archetypes?utm_source=archetypes&utm_campaign=creator"}>"The Inner World of Archetypes: A Guide to Integrating the 12 Jungian Archetypes"</a> e-book. It delves into the complexities of the Creator archetype and provides practical steps for integrating it into our lives in a healthy and fulfilling way. Don't let the Creator archetype control and ruin your life - take control of it and use it to create a life worth living.
</p>
<More arch={name}/>
{this.state ? (
  <div>
    <Graph best={this.state.best}  data ={[
{

"id": this.state.names_arr[0],
"label": this.state.names_arr[0],
"value": this.state.vals_arr[0],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[1],
"label": this.state.names_arr[1],
"value": this.state.vals_arr[1],
"color": "hsl(336, 70%, 50%)"
},
{
"id": this.state.names_arr[2],
"label": this.state.names_arr[2],
"value": this.state.vals_arr[2],
"color": "hsl(60, 70%, 50%)"
},
{
"id": this.state.names_arr[3],
"label": this.state.names_arr[3],
"value": this.state.vals_arr[3],
"color": "hsl(54, 70%, 50%)"
},
{
"id": this.state.names_arr[4],
"label": this.state.names_arr[4],
"value": this.state.vals_arr[4],
"color": "hsl(14, 70%, 50%)"
},
{

"id": this.state.names_arr[5],
"label": this.state.names_arr[5],
"value": this.state.vals_arr[5],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[6],
"label": this.state.names_arr[6],
"value": this.state.vals_arr[6],
"color": "hsl(336, 70%, 50%)"
},
{
"id": this.state.names_arr[7],
"label": this.state.names_arr[7],
"value": this.state.vals_arr[7],
"color": "hsl(60, 70%, 50%)"
},
{
"id": this.state.names_arr[8],
"label": this.state.names_arr[8],
"value": this.state.vals_arr[8],
"color": "hsl(54, 70%, 50%)"
},
{
"id": this.state.names_arr[9],
"label": this.state.names_arr[9],
"value": this.state.vals_arr[9],
"color": "hsl(14, 70%, 50%)"
},
{

"id": this.state.names_arr[10],
"label": this.state.names_arr[10],
"value": this.state.vals_arr[10],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[11],
"label": this.state.names_arr[11],
"value": this.state.vals_arr[11],
"color": "hsl(336, 70%, 50%)"
}
]}/>

<Mail best={this.state.best}/>
<MyVideo/>
</div>




) : (
  <div><i>Take the test on the <a href="/">main page</a> to see your archetype breakdown.</i>


  <br/>
<MyVideo/>

  </div>
)}
          </section>

        </div>
      </Layout>
    )
  }
}

export default Result
